import Vue from 'vue'
import App from './App.vue'
import router from '../router/index';
import VueScrollTo from 'vue-scrollto';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN';
import BaiduMap from "./components/vue-baidu-map/components";
import '../mockjs/index';
Vue.config.productionTip = false;
Vue.use(VueScrollTo);
Vue.use(ElementUI, { locale });
Vue.use(BaiduMap, {
  ak: 'Mo38ILqWRGZySfGGEC5LuWrY0nQ26hmk'
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
