<template>
  <div class="main_box">
    <section class="section_box">
      <div class="conent2_box">
        <div :class="[`section2_left_box`, {active: isVisible==0}]">
          <h1 class="head_name">加入九吨</h1>
          <div class="icon_origin"></div>
        </div>
        <div :class="[`section2_right_box`, {active: isVisible==0}]">
          <div class="image_box">
            <el-image class="img" src="https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job01.png"></el-image>
            <div class="tit">高级UI设计师</div>
            <el-button class="btn" type="warning" plain>查看职务</el-button>
          </div>
          <div class="image_box">
            <el-image class="img" src="https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job02.png"></el-image>
            <div class="tit">安卓工程师</div>
            <el-button class="btn" type="warning" plain>查看职务</el-button>
          </div>
          <div class="image_box">
            <el-image class="img" src="https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job03.png"></el-image>
            <div class="tit">高级产品经理</div>
            <el-button class="btn" type="warning" plain>查看职务</el-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section_box">
      <div class="conent1_box">
        <div :class="[`section1_left_box`, {active: isVisible==1}]">
          <h1 class="head_name">九吨福利</h1>
          <div class="icon_origin"></div>
        </div>
        <div class="section1_right_box">
          <div
            
            v-for="item in contentList"
            :key="item.index"
            :style="{ background: backgrFundColor(item.index) }"
            :class="[`conent_title${item.index}`, {active: isVisible==1}]"
          >
            <div class="num">{{ item.num }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="cont">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_box">
      <div class="conent_box">
        <div class="section1_top">
          <div :class="[`title_text`, {active: isVisible==2}]">
            <h1 class="head_name">联系我们</h1>
            <div class="icon_origin"></div>
          </div>
          <div :class="[`translate_righe`, {active: isVisible==2}]">
            <div class="translate_righe_text">
              地址：重庆市渝北区洪湖西路24号B幢11、12F
            </div>
            <div class="translate_righe_text">
              电话：023-67463753；17300280378
            </div>
            <div class="translate_righe_text">邮箱：hr@nineton.cn</div>
          </div>
        </div>
        <div
          :class="[`section1_bottom`, {active: isVisible==2}]"
          @mouseenter="pauseScroll"
          @mouseleave="resumeScroll"
        >
          <baidu-map
            id="map"
            class="map1"
            :center="centerName"
            :scroll-wheel-zoom="true"
            :zoom="zooms"
            @ready="ready"
          >
          </baidu-map>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      centerName: { lng: 116.404, lat: 39.915 },
      zooms: 15,
      isScrollPaused: false,
      isVisible: 0,
      contentList: [
        {
          num: null,
          title: "",
          content: "",
          index: 0,
          isVisible: false,
        },
        {
          num: "01",
          title: "薪酬待遇",
          content: "同行业有竞争的薪酬，五险一金，丰厚的年终奖",
          index: 1,
          isVisible: false,
        },
        {
          num: "02",
          title: "工作时间",
          content: "周末双休，弹性上下班时间，带薪年假",
          index: 2,
          isVisible: false,
        },
        {
          num: "03",
          title: "餐饮零食",
          content: "午餐补贴，每日不同零食供应，每月豪华下午茶",
          index: 3,
          isVisible: false,
        },
        {
          num: "04",
          title: "员工福利",
          content: "生日福利，节日福利，团建活动，员工旅游",
          index: 4,
          isVisible: false,
        },
        {
          num: "05",
          title: "晋升空间",
          content: "完善的晋升体系，优秀人才优先考虑晋升机会",
          index: 5,
          isVisible: false,
        },
        {
          num: "06",
          title: "办公环境",
          content: "舒适宽敞的办公环境，配备齐全的办公设施",
          index: 6,
          isVisible: false,
        },
        {
          num: "07",
          title: "培训学习",
          content: "丰富的培训课程，提升专业技能，拓展视野",
          index: 7,
          isVisible: false,
        },
      ],
    };
  },
  components: {},
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(entry.target.parentNode.children).indexOf(entry.target);
            this.isVisible = index;
            // this.contentList[index].isVisible = true;
            // 停止观察已触发的模块
            // observer.unobserve(entry.target);
        }
      });
    },{
      threshold: 0.2, // 交叉比例达到50%时触发回调
    });
    
    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    })
  },
  created() {},
  methods: {
    ready({ BMap, map }) {
      console.log(BMap, map);
    },
    backgrFundColor(index) {
      if (index < 4) {
        if (index % 2 == 0) {
          return "#fff";
        } else {
          return "hsla(0, 0%, 85%, .1)";
        }
      } else {
        if (index % 2 == 0) {
          return "hsla(0, 0%, 85%, .1)";
        } else {
          return "#fff";
        }
      }
    },
    pauseScroll() {
      console.log("暂停滚动");
      const mapContainer = document.querySelector(".section1_bottom");
      mapContainer.addEventListener(
        "wheel",
        (event) => {
          console.log(event, "----event");
          event.stopPropagation(); // 阻止事件冒泡
          event.preventDefault(); // 阻止默认滚动行为
        },
        { passive: true }
      );
    },
    resumeScroll() {
      console.log("恢复滚动");
      if (this.isScrollPaused) {
        // document.removeEventListener('wheel', this.preventScroll);
        // window.onscroll = function() { return true; }
        this.isScrollPaused = false;
      }
    },
    preventScroll(event) {
      console.log(event, "------event");
      // event.isTrusted && event.preventDefault(); // 阻止默认滚动行为
      event.preventDefault(); // 阻止默认滚动行为
    },
  },
};
</script>

<style scoped lang="less">
@import url("./index.css");
::v-deep .BMap_bubble_title {
  font-size: 14px;
  font-weight: bold;
  color: #d07852;
}

::v-deep .BMap_cpyCtrl {
  display: none;
}

::v-deep .anchorBL {
  display: none;
}
</style>