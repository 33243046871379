<template>
  <div class="main_box">
    <section id="section1" class="section_box1">
      <div class="sections_box">
        <div :class="[`contact1_left`, { active: isVisible == 0 }]">
          <div class="text_box">
            <h1 class="head_name">九吨生活</h1>
            <div class="icon_origin"></div>
            <div class="transla_left"></div>
          </div>
        </div>
        <div :class="[`contact1_right`, { active: isVisible == 0 }]">
          <div class="img_box">
            <el-image class="img_box1" :src="img" fit="contain"></el-image>
            <el-image class="img_box2" :src="img1" fit="contain"></el-image>
          </div>
          <div class="img_box">
            <el-image class="img_box3" :src="img1" fit="contain"></el-image>
            <el-image class="img_box4" :src="img1" fit="contain"></el-image>
          </div>
          <div class="img_box">
            <el-image class="img_box5" :src="img1" fit="contain"></el-image>
            <el-image class="img_box6" :src="img6" fit="contain"></el-image>
          </div>
        </div>
      </div>
    </section>

    <section id="section3" class="section_box1">
      <div class="sections_box">
        <div :class="[`contact1_left`, { active: isVisible == 1 }]">
          <div>
            <h1 class="head_name">九吨新闻</h1>
            <div class="icon_origin"></div>
          </div>
          <div class="transla_left">
            <el-button
              v-show="currentIndex"
              @click="prevSlide"
              type="warning"
              icon="el-icon-back"
              circle
            ></el-button>
            <span class="inages_num"
              >{{ currentIndex + 1 }} / {{ items.length }}</span
            >
            <el-button
              @click="nextSlide"
              type="warning"
              icon="el-icon-right"
              circle
            ></el-button>
          </div>
        </div>
        <div :class="[`contact1_right`, { active: isVisible == 1 }]">
          <el-carousel ref="carousel" :interval="5000" arrow="always" :autoplay="false" indicator-position="none">
            <el-carousel-item v-for="item in items" :key="item.id">
              <div v-if="item.id%2 == 1" class="container">
                <div class="top-section">
                  <img
                    class="top-left-image"
                    :src="item.image"
                    alt="Top Left Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title }}</h1>
                    <p>{{ item.description }}</p>
                  </div>
                  <img
                    class="top-left-image"
                    :src="item.image1"
                    alt="Top Left Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title }}</h1>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
                <hr />
                <div class="bottom-section">
                  <img
                    class="bottom-left-image"
                    :src="item.image2"
                    alt="Bottom Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title2 }}</h1>
                    <p>{{ item.description2 }}</p>
                  </div>
                </div>
              </div>
              <div v-else class="container">
                <div class="bottom-section">
                  <img
                    class="bottom-left-image"
                    :src="item.image2"
                    alt="Bottom Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title2 }}</h1>
                    <p>{{ item.description2 }}</p>
                  </div>
                </div>
                <hr />
                <div class="top-section">
                  <img
                    class="top-left-image"
                    :src="item.image"
                    alt="Top Left Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title }}</h1>
                    <p>{{ item.description }}</p>
                  </div>
                  <img
                    class="top-left-image"
                    :src="item.image1"
                    alt="Top Left Image"
                  />
                  <div class="text-box">
                    <h1>{{ item.title1 }}</h1>
                    <p>{{ item.description1 }}</p>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      isVisible: 0,
      img: require("../../assets/image/a.png"),
      img1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life2.png",
      img6: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life6.png",
      img7: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
      items: [
        {
          id: 1,
          image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title: "11月生日会",
          description: "新的一岁每一天都和蛋糕一样甜蜜~",
          image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title1: "11月生日会",
          description1: "新的一岁每一天都和蛋糕一样甜蜜~",
          image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title2: "11月生日会",
          description2: "新的一岁每一天都和蛋糕一样甜蜜~",
        },
        {
          id: 2,
          image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title: "1024程序员节",
          description: "带你躺平~",
          image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title1: "1024程序员节",
          description1: "带你躺平~",
          image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title2: "1024程序员节",
          description2: "带你躺平~",
        },
        {
          id: 3,
          image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title: "11月生日会",
          description: "新的一岁每一天都和蛋糕一样甜蜜~",
          image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title1: "11月生日会",
          description1: "新的一岁每一天都和蛋糕一样甜蜜~",
          image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
          title2: "11月生日会",
          description2: "新的一岁每一天都和蛋糕一样甜蜜~",
        },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    nextSlide(data, index) {
      console.log(data,"------data",index,"------index")
      
      this.$refs.carousel.next();
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      // window.scrollTo(0, 0);
    },
    prevSlide() {
      this.$refs.carousel.prev();
      this.currentIndex = this.currentIndex - 1;
      // window.scrollTo(0, 0);
      
    },
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
            // this.contentList[index].isVisible = true;
            // 停止观察已触发的模块
            // observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );

    const moduleElement = document.querySelectorAll(".section_box1");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
  },
  created() {},
};
</script>

<style scoped lang="less">
@import url("./index.css");
</style>