import Mock  from "mockjs";
Mock.mock("/api/user","get",{
    'status': 200,
    message: "请求成功",
    'data': {
        name: "@name",
        age: "@integer(1, 100)",
        address: "@county(true)",
        'id': 1,
    }
});
Mock.mock('/api/postData', 'post', (options) => {
    const body = JSON.parse(options.body); // 获取请求体数据
    return {
      code: 200,
      message: '提交成功',
      data: {
        receivedData: body,
      },
    };
  });