import axios from "axios";
// axios.defaults.baseURL = baseUrl; // 这是调用数据接口
/**
 * 请求前拦截
 */
axios.interceptors.request.use(config => {
    config.headers = {
        'Content-Type': 'application/json; charset=utf-8', // 设置跨域头部,虽然很多浏览器默认都是使用json传数据，但咱要考虑IE浏览器。
    }
    return config
}, (error) => {
    return Promise.reject(error)
})
/**
 * 请求响应拦截
 */
axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error)
})
export default axios;

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
          params: params
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  
  /**
   * post 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  export function post(url, data) {
    return new Promise((resolve, reject) => {
      axios.post(url, data)
        .then(response => {
          resolve(response.data)
        }, err => {
          reject(err)
        })
    })
  }


