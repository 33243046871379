<template>
  <div class="home_box">
    <nav class="header_box">
      <ul clsass>
        <li @click="handelColor(1)">
          <a href="/" v-scroll-to="'#section1'" :class="getActiveClass(1)"
            >首页</a
          >
        </li>
        <li @click="handelColor(2)">
          <a href="" v-scroll-to="'#section1'" :class="getActiveClass(2)"
            >业务</a
          >
        </li>
        <li @click="handelColor(3)">
          <a href="" v-scroll-to="'#section1'" :class="getActiveClass(3)"
            >简介</a
          >
        </li>
        <li @click="handelColor(4)">
          <a href="" v-scroll-to="'#section1'" :class="getActiveClass(4)"
            >文化</a
          >
        </li>
        <li @click="handelColor(5)">
          <a href="" v-scroll-to="'#section1'" :class="getActiveClass(5)"
            >联系我们</a
          >
        </li>
      </ul>
    </nav>
    <div class="main_box">
      <router-view></router-view>
    </div>
    <div class="footer_box">
      <i v-if="isScrollToTop" class="el-icon-arrow-down icon" @click="goToTop"></i>
    </div>
  </div>
</template>

<script>
import { GetFaultInfo, postData } from "@/https/api.js";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      isScrollToTop: true,
      totalDuration: 6,
      activeIndex: 1,
    };
  },
  mounted() {
    window.addEventListener("wheel", this.handleScroll, 200);
    this.startAnimation();
  },
  created() {
    console.log(this.$route, "-----");
    if (this.$route.path == "/Business") {
      this.activeIndex = 1;
      this.getActiveClass(1);
    } else if (this.$route.path == "/About") {
      this.activeIndex = 2;
      this.getActiveClass(2);
    } else if (this.$route.path == "/Brief") {
      this.activeIndex = 3;
      this.getActiveClass(3);
    } else if (this.$route.path == "/Contact") {
      this.activeIndex = 4;
      this.getActiveClass(4);
    } else if (this.$route.path == "/Culture") {
      this.activeIndex = 5;
      this.getActiveClass(5);
    }
    this.GetFaultInfoFun();
    this.postDataFun();
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll, 200);
  },
  methods: {
    // get接口请求
    GetFaultInfoFun() {
      GetFaultInfo()
        .then((res) => {
          console.log(res, "----res");
        })
        .catch((err) => {
          return err;
        });
    },
    // post接口请求
    postDataFun() {
      let params = {
        name: "张三",
        age: 18,
        sex: "男",
      };
      postData(params)
        .then((res) => {
          console.log(res, "----res_post");
        })
        .catch((err) => {
          return err;
        });
    },
    startAnimation() {
      const moveTime = (this.totalDuration * 1) / 2; // 移动时间
      const pauseTime = (this.totalDuration * 1) / 2; // 停顿时间
      setInterval(() => {
        // 更新内容
      }, (moveTime + pauseTime) * 1000); // 每次更新的时间间隔
    },
    handelColor(index) {
      this.activeIndex = index;
      if (index === 1) {
        if (this.$route.path !== "/Business") {
          this.$router.push("/"); // 只有路径不同时才跳转
        }
      } else if (index === 2) {
        if (this.$route.path !== "/About") {
          this.$router.push("/About"); // 只有路径不同时才跳转
        }
      } else if (index === 3) {
        if (this.$route.path !== "/Brief") {
          this.$router.push("/Brief"); // 只有路径不同时才跳转
        }
      } else if (index === 4) {
        if (this.$route.path !== "/Contact") {
          this.$router.push("/Contact"); // 只有路径不同时才跳转
        }
      } else if (index === 5) {
        if (this.$route.path !== "/Culture") {
          this.$router.push("/Culture"); // 只有路径不同时才跳转
        }
      }
    },
    getActiveClass(index) {
      return this.activeIndex === index ? "active" : "";
    },
    handleScroll(event) {
      if (event.deltaY > 0) {
        // 向下滚动
        this.goToTop();
        // window.scrollTo({
        //   top: window.scrollY + window.innerHeight, // 滚动到下一屏
        //   behavior: "smooth", // 平滑滚动
        // });
      } else {
        // 向上滚动
        this.isScrollToTop = true;
        window.scrollTo({
          top: window.scrollY - window.innerHeight, // 滚动到下一屏
          behavior: "smooth", // 平滑滚动
        });
      }
    },
    goToTop() {
      const currentScroll = window.scrollY; // 当前滚动位置
      const viewportHeight = window.innerHeight; // 视口高度
      const totalHeight = document.body.scrollHeight; // 页面总高度
      // 计算目标滚动位置
      let targetScroll = currentScroll + viewportHeight;
      // 如果已经到达底部，则不再滚动
      if (targetScroll > totalHeight - viewportHeight) {
        this.isScrollToTop = false;
        targetScroll = totalHeight - viewportHeight;
      }else{
        this.isScrollToTop = true;
      }
      // 滚动到目标位置
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url("./index.css");
/* 全局样式 */
</style>