<template>
  <div class="main_box">
    <section id="section1" class="section_box">
      <div class="section1_box">
        <div :class="[`sections1_left`, { active: isVisible == 0 }]">
          <div class="text_box">
            <h1 class="head_name">重庆市九吨科技有限公司</h1>
            <div class="icon_origin"></div>
            <div class="translate_lefts1">
              九吨科技秉承着“务实、创新、担当、热爱”的创业理念，打造了一家年轻有活力的科技型创新企业。
            </div>
          </div>
        </div>
        <div class="sections1_right1">
          <el-image
            v-for="item in list"
            :key="item.id"
            :src="item.images"
            class="icon_origin1"
            :class="[`icon_origin${item.id}`, { active: isVisible == 0 }]"
          ></el-image>
        </div>
      </div>
    </section>
    <section id="section2" class="section_box">
      <div class="section1_box">
        <div :class="[`section1_left1`, { active: isVisible == 1 }]">
          <div class="title_box">
            <h1 class="head_name">胡建安</h1>
            <span class="title_span">九吨科技董事长/重庆大学硕士</span>
          </div>
          <div class="icon_origin"></div>
          <div class="translate_left2">
            作为九吨科技创始人，将早年的3人创业团队通过十年来的运作，发展到今天拥有350余名员工的规模，其在团队管理、人员激励等有着独到建树，更是对app开发、推广、运营都有很丰富的实战经验，公司开发的包括新晴天气、手写输入法等在内优秀互联网产品。
          </div>
        </div>
        <div class="section1_right2">
          <div :class="[`img1class_box1`, { active: isVisible == 1 }]">
            <el-image class="img1class1" :src="img1"></el-image>
            <!-- <el-image class="img1class2" :src="img0" ></el-image> -->
          </div>
          <div :class="[`img1class_box2`, { active: isVisible == 1 }]">
            <el-image class="img2class1" :src="img2"></el-image>
            <el-image class="img2class2" :src="img3"></el-image>
          </div>
        </div>
      </div>
    </section>
    <section id="section3" class="section_box">
      <div class="section1_box1">
        <div
          class="scroll-container"
          @mouseenter="pauseScroll"
          @mouseleave="resumeScroll"
        >
          <div class="scroll-content" :style="scrollStyle">
            <div
              v-for="(item, index) in scrollList"
              :key="index"
              class="scroll-item"
            >
              <div class="conent_box1">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue1" class="icon_origin"></div>
              </div>
              <!-- <hr> -->
              <div class="conent_box2">
                <h6 class="conent_h1">{{ item.title1 }}</h6>
                <div class="conent_time">{{ item.time1 }}</div>
                <div v-if="item.isTrue2" class="icon_origin"></div>
              </div>
            </div>
            <!-- 复制一份内容以实现无缝滚动 -->
            <div
              v-for="(item, index) in scrollList"
              :key="'copy-' + index"
              class="scroll-item"
            >
            <div class="conent_box1">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue1" class="icon_origin"></div>
              </div>
              <!-- <hr> -->
              <div class="conent_box2">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue2" class="icon_origin"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Brief",
  data() {
    return {
      isVisible: 0,
      items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'], // 滚动内容
      isPaused: false, // 是否暂停滚动

      img0: "",
      img1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_shen.png",
      img2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_face.png",
      img3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_gao_face.png",
      list: [
        {
          id: 1,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/d643985fb355661564dc1587a27fdfa5.png",
        },
        {
          id: 2,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/6a0d4832ecf5df8da725081c498f0feb.png",
        },
        {
          id: 3,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/b6b87fcd5c7308b08f7802057eec41c3.png",
        },
        {
          id: 4,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/306bf2b54e42e1794163f549d0c43049.png",
        },
      ],
      scrollList: [
        {
          id: 1,
          title: "九吨科技成立，九吨科技成立",
          time: "2020-05",
          isTrue2: true, // 是否显示
          title1: "懒猫试玩App上线",
          time1: "2020-10",
          isTrue1: true,
        },
        {
          id: 2,
          title: "赋比兴科技当选“2022年度重庆市重点软件和信息服务-成长型企业”",
          time: "2020-10",
          isTrue2: true,
          title1: "开启“九吨红人榜”评选活动",
          time1: "2020-10",
          isTrue1: true,
        },
        {
          id: 3,
          title: "赋比兴科技入选“两江新区2023年度软件和信息服务企业综合竞争力20强",
          time: "2020-10",
          isTrue1: false,
          isTrue2: true,
          title1: "九吨原创IP形象“吨总”诞生",
          time1: "2020-10",
        },
        {
          id: 4,
          title: "九吨全资子公司（重庆赋比兴科技有限公司）成立",
          time: "2020-10",
          isTrue1: true,
          title1: "2022年9月 九吨新加坡公司（NINETON PTE. LTD.）成立",
          time1: "2020-10",
          isTrue2: true,

        }
      ],
    };
  },
  computed: {
    scrollStyle() {
      return {
        animationPlayState: this.isPaused ? 'paused' : 'running',
      };
    },
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
            // this.contentList[index].isVisible = true;
            // 停止观察已触发的模块
            // observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );

    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
  },
  methods: {
     // 鼠标划入时暂停滚动
     pauseScroll() {
      this.isPaused = true;
    },
    // 鼠标划出时恢复滚动
    resumeScroll() {
      this.isPaused = false;
    },
  },

};
</script>
<style scoped lang="less">
@import url("./index.css");
/* 全局样式 */
</style>