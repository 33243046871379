<template>
  <div class="main_box">
    <section id="section1" class="section_box">
      <div class="section4_box">
        <div class="section4_left">
          <div class="text_box">
            <h1 class="head_name">九吨业务</h1>
            <div class="icon_origin"></div>
            <div :class="[`translate_left4`, { active: isVisible == 0 }]">
              <el-image class="img_box" :src="items[currentIndex].url1"></el-image>
              <h3 class="head_h3">{{ items[currentIndex].title }}</h3>
              <div class="conent_text">
                {{ items[currentIndex].conent }}
              </div>
              <el-button class="btn" type="warning" plain>IOS</el-button>
            </div>
            <div class="transla_left">
              <el-button
                v-show="currentIndex"
                @click="prevSlide"
                class="prev_btn"
                type="warning"
                icon="el-icon-back"
                circle
              ></el-button>
              <div class="inages_num">
                {{ currentIndex + 1 }} / {{ items.length }}
              </div>
              <el-button
                @click="nextSlide"
                type="warning"
                class="prev_btn"
                icon="el-icon-right"
                circle
              ></el-button>
            </div>
          </div>
        </div>
        <div :class="[`section4_right1`, { active: isVisible == 0 }]">
          <el-image class="section4_right2_img" :src="items[currentIndex].url3"></el-image>
          <el-image class="section4_right1_img" :src="items[currentIndex].url2"></el-image>
          <el-image class="section4_right3_img" :src="items[currentIndex].url4"></el-image>
        </div>
      </div>
    </section>
    <section id="section2" class="section_box">
      <div :class="[`section2_box`, { active: isVisible == 1 }]">
        <div class="section4_left2">
          <h1 class="head_name">业务合作</h1>
          <div class="icon_origin"></div>
          <div class="translate_left1">
            <h1 class="translate_left_h1">移动APP广告合作</h1>
            <div class="translate_left_name">姓名：王娇</div>
            <div class="translate_left_mail">邮箱：wangjiao@nineton.cn</div>
          </div>
        </div>
        <div class="section4_right2">
          <el-image class="section4_right4_img" :src="url5"></el-image>
        </div>
      </div>
    </section>
    <section id="section3" class="section_box">
      <div class="section1_box1">
        <div class="section1_left6">
          <h1 class="head_name">SDK业务</h1>
          <div class="icon_origin"></div>
          <div class="translate_left6">
            <div class="sdk-wrapper" v-for="(item,index) in sdkList" :key="index">
              <div class="sdk-card">
                <div class="sdk-version">{{ item.version }}</div>
                <div class="sdk-info">
                  <div class="info-item" v-for="(info, i) in item.info" :key="i">
                    <span class="label">{{ info.label }}：</span>
                    <span class="value">{{ info.value }}</span>
                  </div>
                </div>
              </div>
              <div class="sdk-links">
                <a v-for="(link, i) in item.links" :key="i" :href="link.url1" target="_blank">{{ link.name }} →</a>
              </div>
            </div>
            <div class="sdk-wrapper"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: 0,
      url5: "https://nineton.cn/img/pic_23.4ec0185a.png",
      currentIndex: 0,
      sdkList:[{
        version: "3.6.8 广告SDK 版本",
        info: [{
          label: "SDK名称：",
          value: "九吨Android广告SDK",
        },{
          label: "SDK所属企业名称：",
          value: "重庆市九吨科技有限公司",
        },{
          label: "SDK说明：",
          value: "用于广告投放、广告监测、广告归因、广告效果优化",
        }],
        links:[
          {
            name: "接入文档",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "影规政策",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "合规使用文档",
            url1: "https://nineton.cn/advertising.html",
          },
        ]
      },{
        version: "3.6.8 广告SDK 版本",
        info: [{
          label: "SDK名称：",
          value: "九吨Android广告SDK",
        },{
          label: "SDK所属企业名称：",
          value: "重庆市九吨科技有限公司",
        },{
          label: "SDK说明：",
          value: "用于广告投放、广告监测、广告归因、广告效果优化",
        }],
        links:[
          {
            name: "接入文档",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "影规政策",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "合规使用文档",
            url1: "https://nineton.cn/advertising.html",
          },
        ]
      }],
      items: [
        {
          name: "1",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "2",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
          title: "可秀",
          conent:
            "可秀是一款超好玩的视频特效APP，腾云驾雾、广场舞、蝴蝶特效等，深受友友们喜爱~",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
        },
        {
          name: "3",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "4",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
      ],
    };
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );

    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
  },
  methods: {
    nextSlide() {
      this.isVisible = 9;
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      setTimeout(() => {
        this.isVisible = 0;
      }, 1000);
    },
    prevSlide() {
      this.isVisible = 9;
      this.currentIndex = this.currentIndex - 1;
      setTimeout(() => {
        this.isVisible = 0;
      }, 1000);
    },
  },
};
</script>
<style scoped lang="less">
@import url("./index.css");
/* 全局样式 */
</style>